<template>
  <div class="cloudplatform">
    <div class="product_desc_1 box_padd">
      <h3>广为智溯云</h3>
      <p>
        广为智溯云平台是基于物联网技术，应用于医药、食品、快消等多领域的大型综合支撑云平台，是企业面向产业链上下游的数据整合平台和连接外部数据的接口。
      </p>
      <div class="desc_img"></div>
      <p>
        平台可实现原料进厂、产品生产、仓储物流、终端销售、市场消费的全链可视化管理，协助企业快速建立满足国家与市场监管的追溯体系，帮助企业实现大数据生态系统信息化管理，完善企业供应链各环节在计划、协同、操作、优化的活动与过程，并可实现高效、精准、灵活的策略分析与决策。
      </p>
    </div>
    <div class="product_desc_2 box_padd">
      <h3>广为智溯云价值</h3>
      <p>
        <span>追溯一站式服务:</span>
        实现原料进厂、产品生产、仓储物流、终端销售、市场消费的全程可视化管理，协助企业快速建立满足国家与市场监管的追溯系统。
      </p>
      <p>
        <span>供应链全程管控:</span>
        完善企业供应链各环节的计划、协调、操作、控制、优化的活动与过程，获得对整个供应链网络的实时观察，找到潜在商业机会，调整决策方向，增加企业销量。
      </p>
      <div class="desc_img"></div>

      <p>
        <span>增加客户认知:</span>
        消除信息孤岛。企业数据与全产业链上下游数据可互通互联、实时共享、高效协同。洞察市场行情，挖掘数据价值，帮助企业更精准的了解用户需求，终端用户习惯。
      </p>
      <p>
        <span>多维度数据分析:</span>
        为企业提供新品研发分析、竞品策略分析、增长预期分析、供应链优化、物流优化分析、销售策略优化、渠道布局优化、区域销售分析、终端销售分析、消费行为分析等数据服务，打造企业快速响应市场需求的生态链。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.cloudplatform {
  .box_padd {
    padding: 50px 30px;
  }
  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/cloudplatform_1.png") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .product_desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      background: url("~@/assets/img/product/cloudplatform_2.png") no-repeat
        center/contain;
    }
  }
}
</style>